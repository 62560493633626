@import url('https://fonts.googleapis.com/css2?family=Montserrat');

.fila-footer {
    position: relative;
    width: 100% !important;
    left: 0px;
    top: 0px;
    height: 100%;
}

.fila-footer-botoes strong {
    position: absolute;
    text-align: left !important;
    left: 20px;
    top: 5px;
    color: #4A5684;
    font-family: 'Montserrat';
    font-size: 16px;
}

.fila-footer-botoes {
    color: white;
    text-align: right;
    position: relative;
    top: 10px;
    margin-right: 10px;
}

.fila-footer-botoes button {
    margin-left: 10px;
    margin-bottom: 10px;
}

.fila-footer-corousel-botao-up {
    position: relative;
    top: -13px;
    left: 10px;
    height: 40px;
}

.fila-footer-carousel-card {
    margin: 10px !important;
    background-color: white;
    position: relative;
    top: 5px;
    height: 130px;
    width: 160px;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;

    box-shadow: 3px 0 3px rgba(0, 0, 0, 0.3),
        0 3px 3px rgba(0, 0, 0, 0.3);
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.3));
}

.fila-footer-carousel-card-content {
    font-family: 'Montserrat';
    font-size: 16px;
    text-align: center;
}

.fila-footer-carousel-card .fila-footer-carousel-card-avatar {
    position: relative;
    top: -15px;
}

.fila-footer-carousel-card-content label {
    position: relative;
    top: -15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #001844;
    font-family: 'Montserrat';
    font-weight: bold;
}

.fila-footer-carousel-card-divider {
    position: relative;
    width: 150px;
    border: 0;
    border-bottom: 1px solid #c0c0c0;
    margin: 8px 0;
    top: -35px;
    left: -20px;
}

.fila-footer-carousel-card-timer {
    font-size: 10px !important;
    position: relative;
    top: -43px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fila-footer-carousel-card-tag {
    position: relative;
    top: -40px;
    font-size: 8px;
}

.fila-footer-carousel-card-icon {
    position: relative;
    left: -5px;
}


.slick-list {
    margin: 0 -12px !important;
}

.slick-slide>div {
    padding: 0 10px !important;
}


@media only screen and (max-width: 800px) {

    .fila-footer-carousel-card {
        width: 125px;
    }
    

}

@media only screen and (max-width: 605px) {
    .fila-footer {
        top: -5px;
    }

    /* 
    .fila-footer-carousel-card .fila-footer-carousel-card-avatar {
        position: relative;
        left: -5px;
    }

    .fila-footer-carousel-card-divider {
        left: -20px;
        width: 150px;
    }

    .fila-footer-carousel-card-content label {
        left: -5px;
    }

    .fila-footer-carousel-card-tag {
        left: -5px;
    }

    .fila-footer-carousel-card-timer {
        left: -5px;
    } */
}

@media only screen and (max-width: 768px) {
    
    .fila-footer {
        position: relative;
        top: 5px;
        height: 1px !important;
    }

    .fila-footer-carousel-card-divider {
        left: -20px;
        width: 117px;
    }

}

@media only screen and (max-width: 500px) and (max-height: 590px) {

    .fila-footer {
        top: 59px;
    }
}

@media only screen and (max-height: 553px) and (max-width: 375px) {
    .fila-footer {
        top: 0px;
    }
}