@import url('https://fonts.googleapis.com/css2?family=Montserrat');

.lista-vendedores-content-configuracoes {
    max-height: 260px;
    overflow-y: auto;
}

.lista-vendedores-configuracoes {
    width: 100%;
    padding: 10px;
}

.list-item-meta-avatar-configuracoes {
    margin-left: 10px;
    height: 50px;
    overflow-y: visible;
    overflow-x: visible;
}



.btn-mudar-ordem {
    margin-right: 10px !important;
}
