.menu-mais-opcoes {
    font-size: 45px;
}


.clicked {
    color: #fff;
    background-color: #1890FF;
    border-radius: 50%;
}

.clicked .icon-container {
    position: relative;
}