@import url(https://fonts.googleapis.com/css2?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);
.component {
  position: relative;
  display: flex;
  height: 1.00rem;
  width: 6.00rem;
  top: 10px
}

.text {
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 0.7rem;
  margin: 0.375rem 0.375rem 0;
  font-family: 'Montserrat';
  color: lightgray;
  position: relative;
  top: -3px
}

.text>p {
  margin-bottom: 0;
  position: relative;
  width: auto;
  white-space: nowrap;
}

.help {
  margin-left: 1rem;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  color: #3196F5;
}

.help:hover {
  color: grey;
  transition: color 0.5s;
}


@media only screen and (max-width: 480px) {
  .component {
    height: 0.80rem;  
    width: 3.50rem;
  }
  
  /* .text {
    display: none;
  } */
/* 
  .help {
    display: none;
  } */
} 
html,
body {
  overflow: hidden;
}

.layout-container {
  display: flex;
  flex-direction: column;
  height: 97vh !important;
}

.layout-header {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-content {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.layout-footer {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(143, 143, 143);
}

.layout-component-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.layout-footer-fixo {
  position: relative;
  top: -15px;
  text-align: right !important;
  border-top: 1px solid lightgray;
}

@media only screen and (max-width: 768px) {
  /* iPad Mini */

  .layout-container {
    height: 92.5vh !important;
  }

  .layout-footer-fixo {
    top: -5px;
  }

}

@media only screen and (max-width: 605px) {

  /* Tablet Android */
  .layout-container {
    height: 88vh !important;
  }

  .layout-footer {
    top: 5px;
  }

}

@media only screen and (max-width: 500px) {
  .layout-footer {
    position: relative;
    top: 0px;
  }
}

@media only screen and (max-height: 740px) {

  /* Meu Celular */
  .layout-container {
    height: 90vh !important;
  }

  .layout-footer-fixo {
    top: 0px;
  }
}

@media only screen and (max-height: 700px) {

  /* Site / Monitor */
  .layout-container {
    height: 97vh !important;
  }

  .layout-footer-fixo {
    top: 0px;
  }
}

@media only screen and (min-height: 812px) and (min-width: 375px) {

  .layout-container {
    height: 98.5vh !important;
  }
}

@media only screen and (max-height: 553px) and (max-width: 375px) {
  .layout-container {
    height: 82.5vh !important;
  }
}


@media only screen and (max-height: 635px) and (max-width: 375px) {
  .layout-container {
    height: 82.5vh !important;
  }
}
@font-face {
  font-family: 'bluve';
  src:  url(/static/media/bluve.92618ee4.eot);
  src:  url(/static/media/bluve.92618ee4.eot#iefix) format('embedded-opentype'),
    url(/static/media/bluve.4318eca5.ttf) format('truetype'),
    url(/static/media/bluve.32cf56c2.woff) format('woff'),
    url(/static/media/bluve.76bc2cd1.svg#bluve) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bluve' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-people_alt:before {
  content: "\e905";
}
.icon-food:before {
  content: "\e904";
}
.icon-lunch:before {
  content: "\e904";
}
.icon-soup:before {
  content: "\e904";
}
.icon-stats-dots:before {
  content: "\e99b";
}
.icon-flag:before {
  content: "\e9cc";
}
.icon-man-woman:before {
  content: "\e9de";
}
.icon-home:before {
  content: "\e900";
}
.icon-coffee:before {
  content: "\e901";
}
.icon-street-view:before {
  content: "\e902";
}
.icon-sign-out:before {
  content: "\e903";
}

.rounded-modal .ant-modal-content {
  border-radius: 10px;
}

.rounded-modal .ant-modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.rounded-modal .ant-modal-footer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.divModalAdicionarVendedores {
  border-radius: 10%;
  min-height: 100px;
}

.checkBoxAdicionarTodosVendedores {
  position: relative;
  top: -33px;  
}

.divListaAddVendedores {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  top: -20px;
  height: 230px;
}

.divListaAddVendedores::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.divListaAddVendedores::-webkit-scrollbar-thumb {
  background-color: #3196f5;
  border-radius: 3px;
}

.divListaAddVendedores::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}


.dividerListaAddVendedores {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ccc;
  margin: 8px 0;  
  position: relative;
  top: -25px;
}

.avatar-with-name {
  position: relative;
  display: flex;
  align-items: center;
  left: 30px;
}

.avatar-with-name h6 {
  margin-left: 10px;
}

.checkBoxAddVendedor {
  margin-top: 8;  
  margin-right: 8;

  position: absolute;
  top: 35%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  z-index: 1;  
}

.ant-avatar {
  border-radius: 10px;
}

.status-indicator {
  position: absolute;
  bottom: -4px;
  right: -4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.status-online {
  border: 3px solid #00FF00;
}

.status-offline {
  border: 3px solid #FF0000;
}

.badge-online {
  background-color: #DDFBEF;
  color: #007753;
}

.badge-offline {
  background-color: #FFCDD2;
  color: #B71C1C;
  }

.badge-position {
  position: relative;
  width: 100px;
  top: 50px !important;
  max-height: 15px;
  width: auto;
  border-radius: 80px;
  text-align: center !important;
  left: -265px; 
}

.badge-position-icon-font {
  position: relative;
  top: -3px;  
}

.badge-position label {
  position: relative;
  top: -5px;  
}

.totalVendedores {
  position: relative;
  font-size: 14px;
  font-family: 'Montserrat';
  text-align: left;
  margin-right: 10px;
}

.divSpinAdicionandoVendedor {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;  
  height: 90%;
}

.divSpinAdicionandoVendedor label {
  font-size: 14px;
  font-family: 'Montserrat';
  margin-left: 20px;
}

.add-vend-steps-container {
  max-width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  max-height: 0px;
  border: none;
}
.menu-loja {
    align-items: center;
    justify-content: space-between;
    height: 260px;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    padding: 0 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 330px;
}

.menu-titulo-loja {
    font-family: 'Montserrat';
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 10px;
    cursor: default !important;
    left: -10px;
}

.menu-items-icone {
    font-size: 20px;
    margin-right: 10px;
    color: #3196F5;
}

.menu-items-label {
    font-size: 16px;
    font-family: 'Montserrat';
}


.fila-cabecalho {
    font-family: 'Montserrat';
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    padding: 0 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.fila-cabecalho img {
    position: relative;
    width: 100px;
    height: auto;
    max-width: 100%;
}

.fila-logo {
    font-size: 22px;
    font-family: 'Montserrat';
}

.logo-bluve {
    position: relative;
    left: -30px;
}

.fila-menu {
    display: flex;
    align-items: center;
}

.fila-profile {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Montserrat';
}

.fila-profile-span {
    margin-right: 10px;
    font-size: 16px;
    font-family: 'Montserrat';
    cursor: pointer;
}

.fila-profile-span-label {
    cursor: pointer;
}

.fila-dropdown-menu {
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: none;
}

.fila-dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.fila-dropdown-menu ul li a {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    color: #333;
}

.fila-dropdown-menu ul li a:hover {
    background-color: #f5f5f5;
}

.profileAvatar {
    position: relative;
    left: 10px;
    background-color: rgb(238, 237, 237);
    border-radius: 50%;
    color: rgba(2, 175, 240, 0.7);
    font-size: 1.5rem;
    text-align: center;
    font-family: 'Montserrat';
    cursor: default;
}

.menu-rodape {
    display: flex;
}

.menu-rodape-botao-sair {
    position: relative;
    width: 100%;
}

.menu-rodape-label-versao {
    display: block;
    text-align: right;
    width: 100%;
    cursor: default !important;
    font-family: 'Montserrat';
    font-size: 11px;
}

.menu-titulo-div-setor {
    margin-bottom: -10px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.menu-titulo-span-setor {
    font-size: 11px;
    font-family: 'Montserrat';
}

.company-dot {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #3196F5;
    margin-right: 10px;
    font-family: 'Montserrat';
    top: 2px;
}

.company-dot-label {
    position: relative;
    top: 4px;
}


@media only screen and (max-width: 800px) {

    .logo-bluve {
        left: -20px;
    }

    .fila-logo {
        font-size: 16px;
        font-family: 'Montserrat';
    }

    .fila-profile-span-label {
        display: none;
    }
}

@media only screen and (max-width: 605px) {

    .logo-bluve {
        left: -20px;
    }

    .fila-logo {
        font-size: 16px;
        font-family: 'Montserrat';
    }

    .fila-profile-span-label {
        display: none;
    }
}


@media only screen and (max-width: 400px) {

    .logo-bluve {
        left: 5px;
    }

}
.lista-vendedores-content-configuracoes {
    max-height: 260px;
    overflow-y: auto;
}

.lista-vendedores-configuracoes {
    width: 100%;
    padding: 10px;
}

.list-item-meta-avatar-configuracoes {
    margin-left: 10px;
    height: 50px;
    overflow-y: visible;
    overflow-x: visible;
}



.btn-mudar-ordem {
    margin-right: 10px !important;
}

.radio-group-opcao-sair-fila {
    margin-right: 10px;
}


.ant-spin-dot {
    position: relative;
    top: 50% !important;
    left: auto !important;
}

.spinner {
    height: 80vh;
    width: 100%;
}

.modal-diario-vendedor {
    position: absolute;
    top: 50px !important;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    height: 60px !important;
    max-height: 60px !important;
}

.buscar-produtos-por-nome-ou-codigo-switch {
    position: relative;
    top: -10px;
    margin-right: 10px;
}

.buscar-produtos-por-nome-ou-codigo {
    margin: 0 auto;
    display: block;
    position: relative;
}

.select-buscar-produtos-por-nome-ou-codigo {
    font-size: 11px;
    margin: 0 auto;
    display: block;
    position: relative;
    text-align: right;
    margin-top: 3px;
    font-style: italic;
}

.buscar-produto-produto-selecionado {
    color: #2db7f5;
    font-family: 'Montserrat';
}

.buscar-produto-produto-selecionado-img {
    position: relative;
    top: -5px;
}

.buscar-produto-label {
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 12pt;
}

.buscar-produto-input-number {
    position: relative;
    top: -7px;
    width: 150px;
    font-size: 1.2rem;
    font-family: 'Montserrat';
    color: #2db7f5;
    text-align: right !important;
}

.buscar-produto-fora-mix-edit {
    width: 450px;
}

.buscar-fora-mix {
    width: 100%;
}
.steps-buscar-produtos {
    position: absolute !important;
    top: 1px !important;
    left: 0;
    width: 100%;
}

.steps-div-cards {
    position: absolute;
    top: 65px;
    left: 0;
    width: 100%;
}

.steps-div-lista-produtos {
    height: 220px;
    overflow: auto;
    position: relative;
    top: -4px;
}

.steps-div-lista-produtos::-webkit-scrollbar {
    width: 10px;
}

.steps-div-lista-produtos::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.steps-div-lista-produtos::-webkit-scrollbar-thumb {
    background: #888;
}

.steps-div-lista-produtos::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.steps-nenhum-produto-informado {
    font-size: 14px;
    font-family: 'Montserrat';
    font-style: italic;
    text-align: center;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

}

@media only screen and (min-height: 923px) {

    .steps-div-lista-produtos {
        height: 470px;
    }
}

@media only screen and (max-height: 795px) {

    /* Meu monitor! Cuidado com outras configurações neste caso! */

    .steps-div-lista-produtos {
        height: 220px;
    }

}

@media only screen and (max-height: 625px) {

    .steps-div-lista-produtos {
        height: 220px;
    }

}


@media only screen and (max-width: 400px) {

    .steps-div-lista-produtos {
        height: 220px !important;
    }

}


.motivosTag {
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  border: solid 0.5px;
  margin-top: 5px;
}

.motivos-span-selecionar-motivos {
  font-size: 16px;
  color: #000;
  margin-top: 10px;
  font-family: 'Montserrat';
}


@media only screen and (max-height: 635px) and (max-width: 375px) {
  .motivos-span-selecionar-motivos {
    position: relative !important;
    top: 5px !important;
    font-size: 8pt !important;
  }
}
.steps-label-opcional {
    position: relative;
    top: -95px;
    left: -80px !important;
    font-family: 'Montserrat';
    font-size: 10pt;
    font-style: italic;
}

.steps-inputstr {
    position: relative;
    margin-bottom: 10px;
    text-align: left;
    width: 400px !important;
}

.steps-label-ou {
    width: 100%;
    text-align: center;
    font-family: 'Montserrat';
    font-size: 14pt;
}



@media only screen and (max-width: 768px) {
    /* iPad Mini */

    .steps-label-opcional {
        left: -60px !important;
    }
}


@media only screen and (max-height: 740px) {
    /* Meu Celular */
    .steps-label-opcional {
        left: -0px !important;
    }

    .steps-inputstr {
        width: 350px !important;
    }
    
  
  }
  
.timeline-timeline {
    font-family: 'Montserrat';
    font-size: 24pt;
    font-style: italic;
}

.div-timeline-pais {
    position: relative;
    height: 100%;
}

.timeline-label {
    position: absolute;
    display: block;
    font-family: 'Montserrat';
    color: gray;
    font-size: 16pt;
    bottom: 0;
}
.encerrar-atendimento-modal {
    position: relative;
    width: 600px !important;
}

.breadcrumbs {
    height: 300px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.step5-finalizar-progress {
    position: relative;
    font-size: 90px;
    display: block;
    width: 100%;
    text-align: center;
    top: 20px
}

.step5-finalizar-label {
    margin-top: 10px;
    font-family: 'Montserrat';
    font-size: 14pt;
    text-align: center;
}

.step5-timer {
    display: grid;
    margin-top: 60px;
    font-family: 'Montserrat';
    text-align: right;
    position: relative;
    width: 100% !important;
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.infinite-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.infinite-progress .ant-spin-dot {
    -webkit-animation: spin 3s linear infinite;
            animation: spin 3s linear infinite;
}



@media only screen and (max-width: 605px) {

    .encerrar-atendimento-modal {
        position: relative;
        width: 550px !important;
    }
    

}

.fora-mix-span-selecionar {
  font-size: 16px;
  color: #000;
  margin-top: 10px;
  font-family: 'Montserrat';
}


@media only screen and (max-height: 635px) and (max-width: 375px) {
  .fora-mix-span-selecionar {
    position: relative !important;
    top: 5px !important;
    font-size: 8pt !important;
  }
}
.floating-action-button {
    position: relative;
    bottom: 24px;
    right: 24px;
    display: flex;
    align-items: flex-end;
}

.floating-action-button__options {
    position: absolute;
    right: -240px;
    /* Ajuste conforme necessário para controlar a distância dos botões em relação ao botão inicial */
    bottom: 0;
    display: flex;
    flex-direction: row;
    grid-gap: 8px;
    gap: 8px;
    top: 19px !important;
    -webkit-transform: translateX(-186%);
            transform: translateX(-186%);
    /* Adiciona o deslocamento lateral */
}

.btn-adicionar-atendimento {
    position: relative;
    margin-right: 10px;
    color: #1890ff;
    height: 50px;
    width: 50px;
    top: 23px;
}

.btn-adicionar-atendimento-icone {
    position: relative;
    font-size: 40px;
    top: -5px
}

.action-botao-venda {
    background-color: #52c41a;
    height: 50px !important;
    width: 50px !important;
}

.action-botao-venda:hover {
    background-color: #52c41a;
}

.action-botao-nao-venda {
    background-color: #ff4d4f;
    height: 50px !important;
    width: 50px !important;
}

.action-botao-nao-venda:hover {
    background-color: #ff4d4f;
}


.action-botao-troca {
    background-color: #1890ff;
    height: 50px !important;
    width: 50px !important;
}

.action-botao-troca:hover {
    background-color: #1890ff;
}

.action-botao-outros {
    background-color: #807702;
    height: 50px !important;
    width: 50px !important;
}

.action-botao-outros:hover {
    background-color: #807702;
}

.action-botao-adicionar-atendimento {
    background-color: #f5c504;
    height: 50px !important;
    width: 50px !important;
}

.action-botao-adicionar-atendimento:hover {
    background-color: #f5c504;
}

.botao-acao-icone {
    position: relative;
    font-size: 28px;
    color: white;
    left: 10px;
    top: -2px;
}

.botao-acao-label {
    position: relative;
    top: 33px;
    left: -40px;
    color: black;
    font-family: 'Montserrat';
    font-style: italic;
}

.floating-action-button-button {
    background-color: #ff4d4f;
    height: 40px !important;
    top: 20px;
    position: relative;
}


@media only screen and (max-width: 768px) {
    /* iPad Mini */

    .btn-adicionar-atendimento {
        top: 23px;
    }

    .floating-action-button__options {
        -webkit-transform: translateX(-212%);
                transform: translateX(-212%);
        /* Adiciona o deslocamento lateral */
    }
}

@media only screen and (max-width: 605px) {

    /* Tablet Android */
    .floating-action-button__options {
        -webkit-transform: translateX(-186%);
                transform: translateX(-186%);
        /* Adiciona o deslocamento lateral */
    }

    .btn-adicionar-atendimento {
        top: 28px;
    }


}

@media only screen and (max-height: 740px) {

    /* Meu Celular */

    .floating-action-button__options {
        -webkit-transform: translateX(-148%);
                transform: translateX(-148%);
        /* Adiciona o deslocamento lateral */
    }
}

@media only screen and (max-height: 700px) and (max-width: 1860px) {
    /* Meu monitor! Cuidado com outras configurações neste caso! */

    .floating-action-button__options {
        -webkit-transform: translateX(-415px);
                transform: translateX(-415px);
    }

}

@media only screen and (max-height: 795px) {

    /* Meu monitor! Cuidado com outras configurações neste caso! */


}

@media only screen and (min-height: 812px) and (min-width: 375px) {
    .floating-action-button__options {
        -webkit-transform: translateX(-340px);
                transform: translateX(-340px);
    }
}

@media only screen and (max-height: 553px) and (max-width: 375px) {
    .floating-action-button__options {
        -webkit-transform: translateX(-340px);
                transform: translateX(-340px);
    }
}

@media only screen and (max-height: 635px) and (max-width: 375px) {
    .floating-action-button__options {
        -webkit-transform: translateX(-340px);
                transform: translateX(-340px);
    }
}
.menu-mais-opcoes {
    font-size: 45px;
}


.clicked {
    color: #fff;
    background-color: #1890FF;
    border-radius: 50%;
}

.clicked .icon-container {
    position: relative;
}
.list-item-meta-avatar {
    margin-left: 10px;
}

.fila-vendedores-disponiveis {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}


.adicionar-vendedores-empty-container .ant-empty-image svg {
    color: #3196f5;
    vertical-align: middle;
    margin-bottom: 10px;
}


.adicionar-vendedores-empty-container-strong {
    color: #4A5684;
    font-family: 'Montserrat';
    font-size: 16px;
}

.adicionar-vendedores-empty-container-description {
    color: #4A5684;
    font-family: 'Montserrat';
    font-size: 14px;
}

.div-vendedores-waiting-load {
    position: relative;
    top: 100px;
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    text-align: center;
}


.fila-vendedores-disponiveis .sellerBadge {
    color: #40a9ff;
    margin-right: 1rem;
}

.btnAdicionarAtendimento {
    width: 2.5rem !important;
    height: 2.5rem !important;
    top: -5px
}

.btnAdicionarAtendimento .iconAdd {
    font-size: 2.188rem;
    line-height: 0;
    text-align: center;
}

.btnSuccess {
    background-color: rgb(76, 177, 76);
    color: white;
    height: 50px;
    width: 100px;
}


.btnSuccess:hover {
    background-color: rgb(76, 177, 76);
    color: white;
}

.btnIniciarAtendimento {
    margin-left: 10px;
    height: 40px;
    color: white;
}

.btnEncerrarAtendimento {
    margin-left: 10px;
    margin-right: 15px;
    height: 40px;
    width: 180px;
    color: white;
}

.lista-vendedores-content {
    height: auto;
}

.fila-vendedores-disponiveis-container {
    position: absolute;
    width: 100%;
    margin-bottom: 1000px !important;
}

@media only screen and (max-width: 768px) {

    /* iPad Mini */
    .lista-vendedores-content {
        height: 648px;
    }
}

@media only screen and (max-width: 605px) {

    /* Tablet Android */
    .lista-vendedores-content {
        height: 487px;
    }
}

@media only screen and (max-height: 740px) {

    /* Meu Celular */
    .lista-vendedores-content {
        height: 415px;
    }
}


@media only screen and (max-height: 700px) {

    /* Site / Monitor */
    .lista-vendedores-content {
        height: 327px;
    }
}

@media only screen and (max-width: 400px) {

    .btnEncerrarAtendimento {
        width: 100px;
    }
}
.fila-footer {
    position: relative;
    width: 100% !important;
    left: 0px;
    top: 0px;
    height: 100%;
}

.fila-footer-botoes strong {
    position: absolute;
    text-align: left !important;
    left: 20px;
    top: 5px;
    color: #4A5684;
    font-family: 'Montserrat';
    font-size: 16px;
}

.fila-footer-botoes {
    color: white;
    text-align: right;
    position: relative;
    top: 10px;
    margin-right: 10px;
}

.fila-footer-botoes button {
    margin-left: 10px;
    margin-bottom: 10px;
}

.fila-footer-corousel-botao-up {
    position: relative;
    top: -13px;
    left: 10px;
    height: 40px;
}

.fila-footer-carousel-card {
    margin: 10px !important;
    background-color: white;
    position: relative;
    top: 5px;
    height: 130px;
    width: 160px;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;

    box-shadow: 3px 0 3px rgba(0, 0, 0, 0.3),
        0 3px 3px rgba(0, 0, 0, 0.3);
    -webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.3));
            filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.3));
}

.fila-footer-carousel-card-content {
    font-family: 'Montserrat';
    font-size: 16px;
    text-align: center;
}

.fila-footer-carousel-card .fila-footer-carousel-card-avatar {
    position: relative;
    top: -15px;
}

.fila-footer-carousel-card-content label {
    position: relative;
    top: -15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #001844;
    font-family: 'Montserrat';
    font-weight: bold;
}

.fila-footer-carousel-card-divider {
    position: relative;
    width: 150px;
    border: 0;
    border-bottom: 1px solid #c0c0c0;
    margin: 8px 0;
    top: -35px;
    left: -20px;
}

.fila-footer-carousel-card-timer {
    font-size: 10px !important;
    position: relative;
    top: -43px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fila-footer-carousel-card-tag {
    position: relative;
    top: -40px;
    font-size: 8px;
}

.fila-footer-carousel-card-icon {
    position: relative;
    left: -5px;
}


.slick-list {
    margin: 0 -12px !important;
}

.slick-slide>div {
    padding: 0 10px !important;
}


@media only screen and (max-width: 800px) {

    .fila-footer-carousel-card {
        width: 125px;
    }
    

}

@media only screen and (max-width: 605px) {
    .fila-footer {
        top: -5px;
    }

    /* 
    .fila-footer-carousel-card .fila-footer-carousel-card-avatar {
        position: relative;
        left: -5px;
    }

    .fila-footer-carousel-card-divider {
        left: -20px;
        width: 150px;
    }

    .fila-footer-carousel-card-content label {
        left: -5px;
    }

    .fila-footer-carousel-card-tag {
        left: -5px;
    }

    .fila-footer-carousel-card-timer {
        left: -5px;
    } */
}

@media only screen and (max-width: 768px) {
    
    .fila-footer {
        position: relative;
        top: 5px;
        height: 1px !important;
    }

    .fila-footer-carousel-card-divider {
        left: -20px;
        width: 117px;
    }

}

@media only screen and (max-width: 500px) and (max-height: 590px) {

    .fila-footer {
        top: 59px;
    }
}

@media only screen and (max-height: 553px) and (max-width: 375px) {
    .fila-footer {
        top: 0px;
    }
}
.header {
    position: absolute;
    width: 100%;
    height: 300px;
    left: 0px;
    top: 0px;
    background: linear-gradient(116.73deg, #02AFF0 2%, #5D21D2 178.06%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.header img {
    width: 130px;
    height: auto;
    margin-top: -200px;
}

.arrow {
    position: absolute;
    width: 200px;
    height: 100%;
    left: 10px;
    top: -37px;
    border-top: 169.355px solid transparent;
    border-bottom: 169.355px solid transparent;
    border-left: 100px solid #02AFF0;
}

.arrow::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -20px;
    width: 20px;
    background: linear-gradient(to right, rgba(2, 175, 240, 0.7), rgba(2, 175, 240, 0));
}

.arrow::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 100%;
    height: 10px;
    background: linear-gradient(to right, rgba(2, 175, 240, 0.7), rgba(2, 175, 240, 0));
}

.arrow span {
    display: block;
    width: 5px;
    height: 5px;
    margin: 3px;
    border-radius: 50%;
    background: #02AFF0;
    float: left;
}

.arrow span:nth-child(1),
.arrow span:nth-child(2),
.arrow span:nth-child(3),
.arrow span:nth-child(4),
.arrow span:nth-child(5),
.arrow span:nth-child(6),
.arrow span:nth-child(7),
.arrow span:nth-child(8),
.arrow span:nth-child(9),
.arrow span:nth-child(10),
.arrow span:nth-child(11),
.arrow span:nth-child(12),
.arrow span:nth-child(13),
.arrow span:nth-child(14),
.arrow span:nth-child(15) {
    opacity: 1;
}

.arrow span:nth-child(16),
.arrow span:nth-child(17),
.arrow span:nth-child(18),
.arrow span:nth-child(19),
.arrow span:nth-child(20),
.arrow span:nth-child(21),
.arrow span:nth-child(22),
.arrow span:nth-child(23),
.arrow span:nth-child(24),
.arrow span:nth-child(25) {
    opacity: 0.5;
}

.telaMeio {
    box-sizing: border-box;
    position: absolute;
    width: 496px;
    height: 430px;
    left: 50%;
    top: 50%;
    box-shadow: 0px 12px 48px rgba(0, 67, 197, 0.12);
    border-radius: 8px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: white;
    z-index: 1;
    overflow: hidden;
    justify-content: center;
    word-break: break-word;
}

.textoLogin {
    position: absolute;
    width: 100%;
    top: 50px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 90px;
    left: 0;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 140%;

    text-align: center;
    color: #4A5684;
}

.login-form {
    max-width: 300px;
    position: relative;
    top: 57%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

.EsqueciSenha {
    float: right;
    color: white !important;
    text-decoration: underline !important;
}

.recaptcha {
    margin-bottom: 2rem;
}

@media only screen and (max-width: 600px) {
    .centerLogin {
        width: 80% !important;
        height: 60% !important;
        position: relative;
    }

    .login-form .ant-input {
        width: 250px;
        margin-left: 20px;
    }


    .ant-form-item-control-input {
        width: 250px !important;
        margin-left: 20px !important;
    }

    .login-form .ant-input-prefix {
        margin-left: 20px;
    }

    .login-form .ant-input-suffix {
        margin-right: 20px;
    }

    .EsqueciSenha {
        position: relative !important;
        margin-right: 10px;
    }

    .textoLogin {
        width: 70%;
        left: 15%;
    }
}

/* 

.centerLogin {
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 30%;
    height: 70%;
    text-align: center;

    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.83);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.83);
    box-shadow: rgba(0, 0, 0, 0.83) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.83) 0px 18px 36px -18px inset;
}



.titleLoginH1 {
    font-size: 48px;
    font-family: 'Poppins-Bold';
    color: white;
    position: relative;
    top: 10px;
}

.titleLoginH6 {
    font-size: 18px;
    font-family: 'Poppins-Bold';
    position: relative;
    top: -20px;
    color: white;
}

.login-form {
    max-width: 300px;
    position: relative;
    top: 30%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

.EsqueciSenha {
    float: right;
    color: white !important;
    text-decoration: underline !important;
}

.bodyLogin .ant-form-explain {
    color: yellow !important;
} */
.header-container {
    position: relative;
    border: 2px solid #e8e8e8;
    border-radius: 4px;
    overflow: auto;
    padding: 5px 5px;
    position: absolute;
    left: 4px;
    top: 90px;
    width: 99.4%;
    height: 100px;
    text-align: center;
}

