@import url('https://fonts.googleapis.com/css2?family=Montserrat');

.timeline-timeline {
    font-family: 'Montserrat';
    font-size: 24pt;
    font-style: italic;
}

.div-timeline-pais {
    position: relative;
    height: 100%;
}

.timeline-label {
    position: absolute;
    display: block;
    font-family: 'Montserrat';
    color: gray;
    font-size: 16pt;
    bottom: 0;
}