@import url('https://fonts.googleapis.com/css2?family=Montserrat');

.rounded-modal .ant-modal-content {
  border-radius: 10px;
}

.rounded-modal .ant-modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.rounded-modal .ant-modal-footer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.divModalAdicionarVendedores {
  border-radius: 10%;
  min-height: 100px;
}

.checkBoxAdicionarTodosVendedores {
  position: relative;
  top: -33px;  
}

.divListaAddVendedores {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  top: -20px;
  height: 230px;
}

.divListaAddVendedores::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.divListaAddVendedores::-webkit-scrollbar-thumb {
  background-color: #3196f5;
  border-radius: 3px;
}

.divListaAddVendedores::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}


.dividerListaAddVendedores {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ccc;
  margin: 8px 0;  
  position: relative;
  top: -25px;
}

.avatar-with-name {
  position: relative;
  display: flex;
  align-items: center;
  left: 30px;
}

.avatar-with-name h6 {
  margin-left: 10px;
}

.checkBoxAddVendedor {
  margin-top: 8;  
  margin-right: 8;

  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;  
}

.ant-avatar {
  border-radius: 10px;
}

.status-indicator {
  position: absolute;
  bottom: -4px;
  right: -4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.status-online {
  border: 3px solid #00FF00;
}

.status-offline {
  border: 3px solid #FF0000;
}

.badge-online {
  background-color: #DDFBEF;
  color: #007753;
}

.badge-offline {
  background-color: #FFCDD2;
  color: #B71C1C;
  }

.badge-position {
  position: relative;
  width: 100px;
  top: 50px !important;
  max-height: 15px;
  width: auto;
  border-radius: 80px;
  text-align: center !important;
  left: -265px; 
}

.badge-position-icon-font {
  position: relative;
  top: -3px;  
}

.badge-position label {
  position: relative;
  top: -5px;  
}

.totalVendedores {
  position: relative;
  font-size: 14px;
  font-family: 'Montserrat';
  text-align: left;
  margin-right: 10px;
}

.divSpinAdicionandoVendedor {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;  
  height: 90%;
}

.divSpinAdicionandoVendedor label {
  font-size: 14px;
  font-family: 'Montserrat';
  margin-left: 20px;
}

.add-vend-steps-container {
  max-width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  max-height: 0px;
  border: none;
}