.ant-spin-dot {
    position: relative;
    top: 50% !important;
    left: auto !important;
}

.spinner {
    height: 80vh;
    width: 100%;
}

.modal-diario-vendedor {
    position: absolute;
    top: 50px !important;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 60px !important;
    max-height: 60px !important;
}
